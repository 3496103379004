<template>
	<div class="home ml-12">
    <!-- Title -->
		<div class="mb-4 mt-6" style="font-size: 28px!important">
      {{$t('Metrics')}}
    </div>

    <!-- Box -->
		<div class="mt-4">{{ $t('Statistic for current event') }}</div>
    <div class="vx-row mt-12">
      <!-- Invited Participants -->
      <div class="vx-col w-1/2 md:w-1/3 xl:w-1/4">
        <statistics-card-line
          hideChart
          class="mb-base events-statistics"
          icon="UsersIcon"
          :statistic="statisticData &&  statisticData.participants ? statisticData.participants.invited : '0'"
          :statisticTitle="$t('Invited Participants')"
        />
      </div>

      <!-- Joined Participants -->
      <div class="vx-col w-1/2 md:w-1/3 xl:w-1/4">
        <statistics-card-line
          hideChart
          class="mb-base events-statistics"
          icon="UsersIcon"
          :statistic="statisticData &&  statisticData.participants ? statisticData.participants.joined : '0'"
          :statisticTitle="$t('Joined Participants')"
        />
      </div>

      <!-- Questions -->
      <div class="vx-col w-1/2 md:w-1/3 xl:w-1/4">
          <statistics-card-line
            hideChart
            class="mb-base events-statistics"
            icon="MessageSquareIcon"
            :statisticTitle="$t('Questions')"
            :statistic="statisticData &&  statisticData.question ? statisticData.question.total : '0'"
            color='success'
          />
      </div>

      <!-- Votings -->
      <div class="vx-col w-1/2 md:w-1/3 xl:w-1/4">
        <statistics-card-line
          hideChart
          class="mb-base events-statistics"
          icon="MousePointerIcon"
          :statistic="statisticData &&  statisticData.voting ? statisticData.voting.total : '0'"
          :statisticTitle="$t('Polls')"
          color='warning'
        />
      </div>
    </div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import eventsApi from '@/api/event';

export default {
  name: 'EventMetrics',
  components: {
    StatisticsCardLine,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      coverImage: require('@/assets/images/backgrounds/night_sky.jpg'),
      isFetching: false,
      orderBy: 'created_at',
      sortBy: 'desc',
      events: [],
      statisticData: [],
      viewers: [],
      isFetchingViewer: false,
      isfilterAllViewer: false,
      isfilterRegisteredViewer: false,
      isfilterUnregisteredViewer: false,
      status: '',
    };
  },
  sockets: {
    connect() {
      this.initSocket();
    },
  },
  methods: {
    initSocket() {
      if (!this.eventData) return;
      // Join Room
      this.$socket.client.emit('join', this.room);
    },
    leaveSocketRoom() {
      this.$socket.client.emit('leave', this.room);
    },
    fetchDetailsEvent() {
      this.isFetching = true;
      const callback = (response) => {
        const event = response.data;
        this.eventData = event;
        this.isFetching = false;
        this.fetchStatictics();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.eventNotFound = true;
      };
      eventsApi.getBySlug(this.eventSlug, callback, errorCallback);
    },
    fetchStatictics() {
      this.isFetching = true;
      const callback = (response) => {
        const data = response.data;
        this.statisticData = data;
        this.isFetching = false;
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.eventNotFound = true;
      };
      eventsApi.getStatistic(this.eventData.id, callback, errorCallback);
    },
    getViewer(reset) {
      this.$vs.loading();
      this.isFetchingViewer = true;
      if (reset) {
        this.page = 1;
        this.viewers = [];
      }
      const params = {
        limit: 1000,
        page: 1,
        status: this.status,
      };
      // if (this.status) params.status = this.status;
      this.isFetchingViewer = true;
      const callback = (response) => {
        const viewers = response.data;
        this.viewers = viewers;
        this.isFetchingViewer = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetchingViewer = false;
        this.eventNotFound = true;
        this.$vs.loading.close();
      };
      eventsApi.getViewer(this.eventSlug, params, callback, errorCallback);
    },
    filterUnregisteredViewer() {
      this.status = 'not_registered';
      this.isfilterUnregisteredViewer = true;
      this.isfilterRegisteredViewer = false;
      this.isfilterAllViewer = false;
    },
    filterRegisteredViewer() {
      this.status = 'registered';
      this.isfilterRegisteredViewer = true;
      this.isfilterUnregisteredViewer = false;
      this.isfilterAllViewer = false;
    },

    filterAllViewer() {
      this.status = 'all';
      this.isfilterAllViewer = true;
      this.isfilterUnregisteredViewer = false;
      this.isfilterRegisteredViewer = false;
    },
    closeViewer() {
      this.isfilterAllViewer = false;
      this.isfilterUnregisteredViewer = false;
      this.isfilterRegisteredViewer = false;
    },
  },
  watch: {
    status() {
      this.getViewer(true);
    },
    eventSlug() {
      if (this.eventSlug) this.initSocket();
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    userName() {
      const name = this.user ? `${this.user.name}` : 'Home';
      return name;
    },
    userId() {
      const userid = this.user && this.user ? this.user : '';
      return userid;
    },
    eventSlug() {
      return this.$route.params.eventSlug;
    },
    room() {
      if (!this.eventData) return null;

      const room = `event_${this.eventData.id}`;
      return room;
    },
  },
  created() {
    this.fetchDetailsEvent();
    this.initSocket();
  },
  beforeDestroy() {
    this.leaveSocketRoom();
  },
  destroyed() {
  },
};
</script>

<style>
.list-viewer{
	max-height: 70px;
	padding: 0px;
	position: relative;
}
.card-viewer{
	min-height: 230px;
	max-height: 230px;
	position: relative;
	overflow-y: auto;
}
</style>
